import { FundingTxModel } from "kz-ui-sdk";

export enum WS_EVENT {
  DEPOSIT_SUCCESS = "WS_DEPOSIT_SUCCESS",
  BALANCE_CHANGED = "WS_BALANCE_CHANGED",
}

export type WSBalanceChangedEventDetail = {
  balance: string;
};

export type WSBalanceChangedEvent = CustomEvent<WSBalanceChangedEventDetail>;

export type WSDepositSuccessEventDetail = {
  fundingTx?: FundingTxModel;
};

export type WSDepositSuccessEvent = CustomEvent<WSDepositSuccessEventDetail>;
