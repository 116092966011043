import { WS_EVENT, WSBalanceChangedEvent, WSDepositSuccessEvent } from "@constants/ws";
import { RootState } from "@stores";
import { isTokenExpired } from "@utils/jwt";
import logger from "@utils/logger";
import { getWsUrl } from "@utils/websocket";
import { AuthToken, WSEventResponse, WSEventType } from "kz-ui-sdk";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useDocumentVisibilityChange } from "./useDocumentVisibilityChange";
import { useEventListener } from "./useEventListener";
import useNetworkStatus from "./useNetworkStatus";
import { useWebSocketManual } from "./useWS";

export function useAppWsEvent() {
  const isOnline = useNetworkStatus();

  const isVisible = useDocumentVisibilityChange({
    // 3 minutes delay
    delay: 180000,
    delayType: "invisible",
  });

  const auth = useSelector<RootState, AuthToken | null | undefined>((state) => state?.auth?.oauth);
  const isRefreshingToken = useSelector<RootState, boolean | undefined>((state) => state?.auth?.isRefreshingToken);

  const token: string | null = useMemo(() => {
    if (auth?.access_token) {
      // Check if token is expired
      if (isTokenExpired(auth.access_token)) {
        logger._console.log("WS token expired");
        return null;
      }
      return auth.access_token;
    }
    return null;
  }, [auth]);

  const isReady = token && !isRefreshingToken && isOnline && isVisible;

  // To prevent multiple connections, it's best not to use the useAppWsEvent hook in multiple components.
  const { lastJsonMessage } = useWebSocketManual<WSEventResponse>({
    url: isReady ? getWsUrl(token) : undefined,
    maxRetries: Infinity,
    heartbeat: true,
  });

  useEffect(() => {
    if (lastJsonMessage) {
      try {
        if (lastJsonMessage.type === "event") {
          switch (lastJsonMessage.payload.event) {
            case WSEventType.DEPOSIT_SUCCESS: {
              const event: WSDepositSuccessEvent = new CustomEvent(WS_EVENT.DEPOSIT_SUCCESS, {
                detail: {
                  fundingTx: lastJsonMessage.payload.fundingTx,
                },
              });
              window.dispatchEvent(event);
              break;
            }

            case WSEventType.BALANCE_CHANGED: {
              const event: WSBalanceChangedEvent = new CustomEvent(WS_EVENT.BALANCE_CHANGED, {
                detail: {
                  balance: lastJsonMessage.payload.balance,
                },
              });
              window.dispatchEvent(event);
              break;
            }

            default:
              break;
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [lastJsonMessage]);
}

export function useWSDepositSuccess(options: { cb?: (event: WSDepositSuccessEvent) => void }) {
  const { cb } = options;
  useEventListener<WSDepositSuccessEvent>({
    eventName: WS_EVENT.DEPOSIT_SUCCESS,
    handler: cb,
  });
}

export function useWSBalanceChanged(options: { cb?: (event: WSBalanceChangedEvent) => void }) {
  const { cb } = options;
  useEventListener<WSBalanceChangedEvent>({
    eventName: WS_EVENT.BALANCE_CHANGED,
    handler: cb,
  });
}
